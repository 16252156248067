@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/_breakpoints";

/** Variable **/
$grey-border: #efefef;
$black: #333333;
$red: #da413f;
$grey-small: #6d6d6d;
$blue: #0e4d95;
/*** Size ***/
$size-18: 1.1rem;
$size-40: 3rem;
$size-24: 1.4rem;
$gradient-text: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);

.sub-header {
	#main-menu {

		-ms-transition: all 0.25s ease-out;
		-moz-transition: all 0.25s ease-out;
		-webkit-transition: all 0.25s ease-out;
		-o-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;

		a {
			color: white;
			text-decoration: none;
			font-size: $size-18;

			&.active {
				font-weight: bold;
			}

		}

		#logo-civipol {
			display: flex;
			width: 170px;
			height: 50px;
			background: transparent url('../../../Static/Images/logo-civipol-expert-white.svg') no-repeat center;
			background-size: contain;

			img {
				opacity: 0;
			}
		}

		.navbar {
			padding: 0;

			.navbar-toggler {
				width: 50px;
				height: 50px;
				background: transparent url('../../../Static/Icons/bouton-menu-toggle.svg') no-repeat center;
				background-size: contain;
				border: none;

				&.collapsed {
					background: transparent url('../../../Static/Icons/bouton-menu-toggle-open.svg') no-repeat center;
					background-size: contain;
				}

				span {
					display: none;
				}
			}
		}

	}


	&.fixed-top {
		background-color: white;

		#logo-civipol {
			background-image: url('../../../Static/Images/logo-civipol-expert-black.svg') !important;
		}

		#base-experts {

			background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);

			p {
				-webkit-text-fill-color: #ffffff !important;
				color: white !important;
			}
		}

		#main-menu {

			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
			height: 100px;

			a {
				color: black;

				&.btn-civipol {
					color: white;
				}
			}

		}
	}
}


#title-header {
	height: 150px;

	&.scrolled {
		padding-top: 115px;
		@include media-breakpoint-down(sm) {
			padding-top: 75px;
		}
	}
}

#main-navbar-nav {
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	background: url('../../../Static/Images/background_mobile.jpg') no-repeat center;
	background-size: cover;

	#main-menu {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		height: 100px;

		#logo-civipol {
			background-image: url('../../../Static/Images/logo-civipol-expert-black.svg');
		}

		#base-experts {

			background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);

			p {
				-webkit-text-fill-color: #ffffff !important;
				color: white !important;
			}
		}
	}

	.menu {
		height: 100%;
		>.container {
			height: 100%;
		}
		.navbar-nav {
			a {
				color: white;
				padding-bottom: 1rem;
				margin-bottom: 1rem;
				border-bottom: 1px solid white;
				margin-right: 0!important;
			}
		}
	}

	&.collapsing {
		display: none;
		-ms-transition: none;
		-moz-transition: none;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
		height: 100vh;
	}
}

