html, body, #root, #app{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Variable **/
/*** Size ***/
.sub-header #main-menu {
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out; }
  .sub-header #main-menu a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem; }
    .sub-header #main-menu a.active {
      font-weight: bold; }
  .sub-header #main-menu #logo-civipol {
    display: flex;
    width: 170px;
    height: 50px;
    background: transparent url(/static/media/logo-civipol-expert-white.f29e29f3.svg) no-repeat center;
    background-size: contain; }
    .sub-header #main-menu #logo-civipol img {
      opacity: 0; }
  .sub-header #main-menu .navbar {
    padding: 0; }
    .sub-header #main-menu .navbar .navbar-toggler {
      width: 50px;
      height: 50px;
      background: transparent url(/static/media/bouton-menu-toggle.04b3ab09.svg) no-repeat center;
      background-size: contain;
      border: none; }
      .sub-header #main-menu .navbar .navbar-toggler.collapsed {
        background: transparent url(/static/media/bouton-menu-toggle-open.114db769.svg) no-repeat center;
        background-size: contain; }
      .sub-header #main-menu .navbar .navbar-toggler span {
        display: none; }

.sub-header.fixed-top {
  background-color: white; }
  .sub-header.fixed-top #logo-civipol {
    background-image: url(/static/media/logo-civipol-expert-black.845eab29.svg) !important; }
  .sub-header.fixed-top #base-experts {
    background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%); }
    .sub-header.fixed-top #base-experts p {
      -webkit-text-fill-color: #ffffff !important;
      color: white !important; }
  .sub-header.fixed-top #main-menu {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: 100px; }
    .sub-header.fixed-top #main-menu a {
      color: black; }
      .sub-header.fixed-top #main-menu a.btn-civipol {
        color: white; }

#title-header {
  height: 150px; }
  #title-header.scrolled {
    padding-top: 115px; }
    @media (max-width: 767.98px) {
      #title-header.scrolled {
        padding-top: 75px; } }

#main-navbar-nav {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: url(/static/media/background_mobile.d25e9121.jpg) no-repeat center;
  background-size: cover; }
  #main-navbar-nav #main-menu {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: 100px; }
    #main-navbar-nav #main-menu #logo-civipol {
      background-image: url(/static/media/logo-civipol-expert-black.845eab29.svg); }
    #main-navbar-nav #main-menu #base-experts {
      background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%); }
      #main-navbar-nav #main-menu #base-experts p {
        -webkit-text-fill-color: #ffffff !important;
        color: white !important; }
  #main-navbar-nav .menu {
    height: 100%; }
    #main-navbar-nav .menu > .container {
      height: 100%; }
    #main-navbar-nav .menu .navbar-nav a {
      color: white;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid white;
      margin-right: 0 !important; }
  #main-navbar-nav.collapsing {
    display: none;
    -webkit-transition: none;
    transition: none;
    height: 100vh; }

.login-form {
  width: 400px; }

.block-title {
  background-image: url(/static/media/visuel_pushInscription.4e5cd436.png); }

.block-signup-title {
  background-image: url(/static/media/visuel_pushLogin.2d12c74e.png); }

.white-color {
  color: #fff; }

.inputBase {
  height: 50px;
  border: 2px solid #EFEFEF;
  border-radius: 3px 3px 0 0; }

.borderStyle {
  border: 2px solid #EFEFEF;
  border-radius: 3px 3px 0 0; }

.errorInputs {
  font-style: italic;
  color: #DA413F;
  text-align: left; }

.grecaptcha-badge {
  bottom: 50px !important; }

@font-face {
  font-family: 'DIN-BOLD';
  src: url(/static/media/DINNextLTPro-Bold.e03e7ca2.otf); }

@font-face {
  font-family: 'DIN-MED';
  src: url(/static/media/DINNextLTPro-Medium.b99f38ab.otf); }

@font-face {
  font-family: 'DIN-LIGHT';
  src: url(/static/media/DINNextLTPro-Light.bc3583d7.otf); }

@font-face {
  font-family: 'HELV-NEUE-LIGHT';
  src: url(/static/media/HelveticaNeueLTStd-Lt.06137036.otf); }

@font-face {
  font-family: 'HELV-NEUE-REG';
  src: url(/static/media/helvetica-regular.1b580d98.ttf); }

@font-face {
  font-family: 'HELV-NEUE-BLD';
  src: url(/static/media/HelveticaNeueLTStd-Cn.0ae67f3d.otf); }

/** Mixin **/
/** Font **/
/** Variable **/
/*** Size ***/
/*** Gradient ***/
ol, ul {
  font-size: 1.11rem;
  font-family: "HELV-NEUE-REG", sans-serif; }

.text-size-low {
  font-size: 0.7rem; }

.text-size-1 {
  font-size: 1rem; }

.pt-6 {
  padding-top: 6rem; }

.react-datepicker-popper {
  display: none; }

.input-file {
  display: none; }

.white-highlight {
  border-radius: 20px;
  padding-left: 1%;
  padding-right: 1%;
  background-color: white; }
  .white-highlight span {
    color: #333333; }

.is-invalid {
  color: #da413f;
  font-style: italic;
  font-size: 1.1rem; }

.sticky-top {
  top: 120px; }

p {
  font-size: 1.1rem; }

a {
  color: #333333; }

small {
  font-size: 80% !important;
  font-weight: 400; }
  small span {
    font-size: inherit !important; }

.grey-small {
  color: #aeaeae; }

#sidebar-left {
  background: #0e4d95 url(/static/media/visuel_onboardingBackground.324f0f8c.png) no-repeat top left;
  background-size: 100%; }
  #sidebar-left h1 {
    font-size: 3rem;
    line-height: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75); }
  #sidebar-left p {
    font-size: 1.1rem;
    line-height: 27px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25); }

#sidebar-right {
  background-color: white;
  position: relative;
  z-index: 0;
  padding-top: 3rem; }
  @media (min-width: 576px) {
    #sidebar-right {
      padding-top: 7rem; } }

@media (min-width: 992px) {
  .wrapper {
    overflow-x: hidden; } }

.min-height-100 {
  min-height: 100vh; }

.blue {
  color: #0e4d95; }

.btn {
  cursor: pointer; }
  .btn.transparent {
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
    box-shadow: none; }
  .btn.btn-signin {
    color: black;
    border-radius: 20px;
    border-width: 2px;
    border-color: #efefef;
    padding: 0.5rem 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 400px;
    font-family: "DIN-MED", sans-serif; }
  .btn.btn-footer {
    border-radius: 20px;
    background-color: white;
    color: #0e4d95;
    padding: 0.5rem 1.5rem;
    font-family: "DIN-MED", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .btn.btn-civipol {
    border-radius: 20px;
    color: white;
    background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
    border: #da413f;
    padding: 0.5rem 1.5rem 0.25rem;
    font-family: "DIN-MED", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .btn.btn-civipol:hover {
      background-image: linear-gradient(-45deg, #9e292e 0%, #15326a 100%); }
  .btn.btn-calendar {
    z-index: 10;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: url(/static/media/icon_form.f4ed818f.svg) no-repeat center;
    background-size: contain; }

form label {
  font-size: 1.1rem; }
  form label[for="search"] {
    font-size: 1.5rem; }
  form label span {
    font-family: "HELV-NEUE-LIGHT", sans-serif; }
  form label.is-invalid {
    font-weight: bold; }

form .search-input {
  box-shadow: 2px 2px 2px 2px #ccc; }

form .form-control {
  border: 2px solid #efefef;
  height: 60px;
  border-radius: 3px;
  font-family: "HELV-NEUE-REG", sans-serif;
  display: inline-flex;
  margin-bottom: 0; }
  form .form-control[name="identity.birthday"] {
    background-color: white; }
  form .form-control::-webkit-input-placeholder {
    font-family: "HELV-NEUE-REG", sans-serif; }
  form .form-control::-ms-input-placeholder {
    font-family: "HELV-NEUE-REG", sans-serif; }
  form .form-control::placeholder {
    font-family: "HELV-NEUE-REG", sans-serif; }
  form .form-control.is-invalid {
    border-color: #efefef;
    background-image: none;
    padding: 0.375rem 0.75rem; }
  form .form-control.search {
    height: 80px; }

form .is-invalid {
  color: #da413f; }

form .is-invalid-label {
  color: #da413f;
  font-weight: bold; }

form .invalid-feedback {
  margin-top: 10px;
  color: #da413f;
  font-style: italic;
  font-size: 1.1rem; }

div[name="abilities"] :not(.underligne) form[name="abilities"] {
  overflow: inherit !important; }
  div[name="abilities"] :not(.underligne) form[name="abilities"] .css-1l76avi-control,
  div[name="abilities"] :not(.underligne) form[name="abilities"] .css-zfes0w-control,
  div[name="abilities"] :not(.underligne) form[name="abilities"] .css-zfes0w-control:hover {
    min-height: 60px;
    height: inherit !important; }

div[name="individualsInformations"] :not(.underligne) form[name="individualsInformations"] {
  overflow: inherit !important; }
  div[name="individualsInformations"] :not(.underligne) form[name="individualsInformations"] .css-1l76avi-control,
  div[name="individualsInformations"] :not(.underligne) form[name="individualsInformations"] .css-zfes0w-control,
  div[name="individualsInformations"] :not(.underligne) form[name="individualsInformations"] .css-zfes0w-control:hover {
    min-height: 60px;
    height: inherit !important; }

.form-group {
  overflow: inherit !important; }
  .form-group .css-1l76avi-control,
  .form-group .css-zfes0w-control,
  .form-group .css-zfes0w-control:hover {
    min-height: 60px;
    height: inherit !important; }

.invalid-feedback {
  margin-top: 10px;
  color: #da413f;
  font-style: italic;
  font-size: 1.1rem; }

.password-advice {
  display: inline-block;
  font-family: "HELV-NEUE-LIGHT", sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  color: #666666;
  font-weight: 400;
  text-align: justify; }

#title-block {
  margin: 0;
  height: 100px; }
  #title-block.account {
    background: midnightblue url(/static/media/visuel_pushLogin.2d12c74e.png) no-repeat center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  #title-block.sign-up {
    background: midnightblue url(/static/media/visuel_pushInscription.4e5cd436.png) no-repeat center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  #title-block h2 {
    font-size: 1.1rem;
    font-weight: 700; }

#reinit-form {
  font-family: "DIN-LIGHT", sans-serif; }
  #reinit-form .encoche-title {
    height: 5px;
    width: 75px;
    background: linear-gradient(45deg, #0f4d95 0%, #da413f 100%); }

#login-form {
  width: 100%; }
  @media (min-width: 576px) {
    #login-form {
      width: 420px; } }
  #login-form .form {
    border-bottom: 2px solid #efefef;
    border-right: 2px solid #efefef;
    border-left: 2px solid #efefef;
    border-radius: 3px; }
    #login-form .form.reinit-form {
      border-top: 2px solid #efefef; }
      #login-form .form.reinit-form .title {
        color: #0f4d95;
        font-family: "DIN-MED", sans-serif;
        font-size: 18px;
        line-height: 23px; }
    #login-form .form .title-block {
      margin: 0;
      height: 100px; }
      #login-form .form .title-block.employee {
        background: midnightblue url(/static/media/background_header.e69ac67c.png) no-repeat center;
        background-size: cover;
        height: 120px; }
      #login-form .form .title-block.account {
        background: midnightblue url(/static/media/visuel_pushLogin.2d12c74e.png) no-repeat center;
        background-size: cover; }
      #login-form .form .title-block.subscribe {
        background: midnightblue url(/static/media/visuel_pushInscription.4e5cd436.png) no-repeat center;
        background-size: cover; }
      #login-form .form .title-block h2 {
        font-size: 1.1rem; }

.employee-auth {
  background: midnightblue url(/static/media/visuel_onboardingBackground.324f0f8c.png) no-repeat center;
  background-size: cover; }

.title-block {
  margin: 0;
  height: 100px; }
  .title-block.employee {
    background: midnightblue url(/static/media/background_footer_smartphone.f2d8dd26.png) no-repeat center;
    background-size: cover; }
  .title-block.account {
    background: midnightblue url(/static/media/visuel_pushLogin.2d12c74e.png) no-repeat center;
    background-size: cover; }
  .title-block.subscribe {
    background: midnightblue url(/static/media/visuel_pushInscription.4e5cd436.png) no-repeat center;
    background-size: cover; }
  .title-block h2 {
    font-size: 1.1rem; }

#create-account {
  background: midnightblue url(/static/media/visuel_pushInscription.4e5cd436.png) no-repeat center;
  background-size: cover;
  border-radius: 3px;
  width: 100%; }
  @media (min-width: 576px) {
    #create-account {
      width: 420px; } }
  #create-account h2 {
    font-size: 1.1rem;
    font-family: "DIN-BOLD", sans-serif; }

.circle-mail-confirmation {
  background-color: #fbfbfb;
  border-radius: 50%;
  text-align: center;
  width: 500px;
  height: 500px;
  position: relative; }

.logo-mail {
  top: 0;
  margin-top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.content-text-circle-mail {
  padding: 10px;
  margin-top: 4rem;
  left: 0; }

#navbar {
  background: white url(/static/media/background_header.e69ac67c.png) no-repeat left center;
  background-size: cover;
  min-height: 270px; }
  #navbar h1 {
    font-family: "DIN-BOLD", sans-serif;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);
    line-height: 58px;
    font-size: 3rem; }
  #navbar .top-header {
    background-color: rgba(0, 0, 0, 0.25); }
    #navbar .top-header .language {
      cursor: pointer; }
      #navbar .top-header .language:before {
        content: "";
        display: inline-flex;
        width: 15px;
        height: 15px;
        margin-right: 0.25rem;
        background: #0000 url(/static/media/icon_language.c359dd51.svg) no-repeat center;
        background-size: contain; }
    #navbar .top-header .language:hover {
      text-decoration: underline; }
    @media (min-width: 576px) {
      #navbar .top-header .top-header-menu {
        height: 40px; } }
    #navbar .top-header .top-header-menu p {
      color: white;
      font-size: 0.9rem; }
    #navbar .top-header .top-header-menu a {
      color: white; }
    #navbar .top-header .top-header-menu .is-validating-notif {
      background-color: white;
      color: #333333;
      border-radius: 20px;
      font-size: 0.9rem; }

.profile-picture {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .profile-picture.profile-picture-large {
    width: 100px;
    height: 100px; }

#footer {
  width: 100%;
  display: flex;
  flex-direction: column; }
  #footer .top-footer {
    padding: 1rem 0;
    background: url(/static/media/background_footer_desktop.d26f0f31.png) no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 992px) {
      #footer .top-footer {
        height: 100px; } }
    #footer .top-footer img {
      height: 65px; }
    @media (max-width: 991.98px) {
      #footer .top-footer aside {
        position: absolute;
        top: 15px;
        right: 0; } }
    #footer .top-footer aside img {
      width: 40px;
      height: 40px;
      cursor: pointer; }
    #footer .top-footer .nav-item {
      padding: 0 0.5rem; }
      #footer .top-footer .nav-item p {
        margin-bottom: 0; }
  #footer .sub-footer {
    padding-top: 2rem;
    padding-right: 0px;
    padding-bottom: 0.5rem;
    padding-left: 0px;
    background-color: #efefef;
    font-family: "HELV-NEUE-LIGHT", sans-serif;
    font-size: 1.2rem; }
    #footer .sub-footer p {
      color: #333;
      font-family: "Helvetica Neue", Arial, Sans-Serif;
      font-size: 14px; }
      #footer .sub-footer p a {
        color: #333; }
    #footer .sub-footer #menu-link-footer {
      flex-direction: row; }
      #footer .sub-footer #menu-link-footer li a {
        color: #333;
        font-family: "Helvetica Neue", Arial, Sans-Serif;
        margin: 0 0 0 0.75rem;
        padding: 0;
        font-size: 14px !important; }
        #footer .sub-footer #menu-link-footer li a:hover {
          color: #333; }

.profil_wrapper {
  padding-top: 4rem;
  padding-left: 7.4rem;
  padding-right: 7.4rem; }

.notifications {
  border: 2px solid #efefef;
  border-radius: 3px;
  padding: 2rem; }
  .notifications .title {
    font-family: "DIN-MED", sans-serif;
    font-size: 1.2rem;
    line-height: 3rem;
    color: #061922;
    text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5); }
  .notifications .text {
    font-family: "HELV-NEUE-LIGHT", sans-serif;
    font-size: 1.1rem; }
  .notifications .btn-close-notif {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: transparent url(/static/media/icon_close-gris.1b04fb58.svg) no-repeat center;
    background-size: contain; }

#base-experts {
  background-color: white;
  border-radius: 20px;
  width: 180px;
  height: 22px; }
  #base-experts p {
    background: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    line-height: 1.4;
    text-shadow: none; }

#rgpdButton {
  background-color: white;
  border: none;
  color: black;
  text-decoration: none; }
  #rgpdButton span:hover {
    text-decoration: underline;
    color: blue; }

#menu {
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out; }
  #menu .btn-menu {
    cursor: pointer;
    border: 2px solid #efefef;
    border-radius: 3px;
    padding: 0.5rem;
    line-height: 1;
    font-size: 1.2rem;
    margin-top: 0.7rem;
    align-items: center;
    width: 100%; }
    #menu .btn-menu:first-of-type {
      margin-top: 0; }
    #menu .btn-menu.mission {
      height: 130px;
      font-family: "DIN-MED", sans-serif; }
    #menu .btn-menu.active {
      background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
      color: white; }
    #menu .btn-menu .number-menu {
      margin-right: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      width: 3rem;
      height: 3rem;
      padding-top: 1rem;
      padding-bottom: 0.75rem;
      border-radius: 50%;
      background-color: #f7f7f7;
      font-family: "DIN-MED", sans-serif; }
      #menu .btn-menu .number-menu.active-number {
        color: black;
        background-color: white; }

::-webkit-input-placeholder {
  font-style: italic; }

:-moz-placeholder {
  font-style: italic; }

::-moz-placeholder {
  font-style: italic; }

:-ms-input-placeholder {
  font-style: italic; }

.profile-container p {
  font-family: "HELV-NEUE-LIGHT", sans-serif; }
  .profile-container p.lang-subtitle {
    font-family: "DIN-MED", sans-serif;
    font-size: 1.1rem; }

.profile-container h1 {
  font-family: "DIN-LIGHT", sans-serif;
  text-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  font-size: 30px;
  color: #000000;
  margin-bottom: 1.5rem;
  margin-left: 20px;
  padding-top: 5px; }
  @media (min-width: 576px) {
    .profile-container h1 {
      line-height: 43px;
      font-size: 36px; } }

.profile-container h3 {
  font-family: "DIN-MED", sans-serif;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 2rem; }

.profile-container .number-menu {
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  background-color: #f7f7f7; }

.profile-container .profile-sections {
  border: 2px solid #efefef;
  border-radius: 3px;
  padding: 1rem;
  width: 100%; }
  @media (min-width: 576px) {
    .profile-container .profile-sections {
      padding: 2rem; } }
  .profile-container .profile-sections .react-datepicker-wrapper {
    width: 100%; }
    .profile-container .profile-sections .react-datepicker-wrapper .react-datepicker__input-container {
      display: block; }
  .profile-container .profile-sections .form-control::-webkit-input-placeholder {
    color: #495057;
    font-style: normal; }
  .profile-container .profile-sections .form-control::-ms-input-placeholder {
    color: #495057;
    font-style: normal; }
  .profile-container .profile-sections .form-control::placeholder {
    color: #495057;
    font-style: normal; }
  .profile-container .profile-sections .form-control:-ms-input-placeholder {
    color: #495057;
    font-style: normal; }
  .profile-container .profile-sections .form-control::-ms-input-placeholder {
    color: #495057;
    font-style: normal; }
  .profile-container .profile-sections .title {
    color: #0f4d95;
    font-family: "DIN-MED", sans-serif;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 2rem; }

.profile-container .confirmation-container {
  margin-top: -4px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #f7f7f7;
  border-radius: 0 0 3px 3px;
  width: 100%;
  text-align: center;
  border-left: 2px solid #efefef;
  border-bottom: 2px solid #efefef;
  border-right: 2px solid #efefef; }

.profile-container .register-modification {
  text-align: center;
  width: 100%; }

.custom-radio {
  padding-left: 2rem; }

.form-check-inline .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  width: 2rem;
  height: 2rem; }

.custom-control-label::selection {
  background-color: transparent; }
  .custom-control-label::selection p,
  .custom-control-label::selection span {
    background-color: transparent; }

.custom-control-input ~ .custom-control-label::before {
  left: -2rem;
  top: 0;
  border-color: #efefef;
  background-color: white;
  width: 2rem;
  height: 2rem; }

.custom-control-input:checked ~ .custom-control-label::before {
  left: -2rem;
  top: 0.25rem;
  border-color: #efefef;
  background-color: white;
  width: 2rem;
  height: 2rem; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: black;
  border-radius: 50%;
  border: 0.25rem solid white;
  width: 1.5rem;
  height: 1.5rem;
  left: -1.75rem; }

.custom-checkbox {
  padding-left: 2rem; }

.custom-control-input ~ .custom-control-label::before {
  left: -2rem;
  top: 0;
  border-color: #efefef;
  background-color: white;
  width: 2rem;
  height: 2rem; }

.custom-control-input ~ .custom-control-label::after {
  width: 2rem;
  height: 2rem; }

.custom-control-input:checked ~ .custom-control-label::before {
  left: -2rem;
  top: 0;
  border-color: #efefef;
  background-color: white;
  width: 2rem;
  height: 2rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: white url(/static/media/icon_checkbox.b6683da9.svg) no-repeat center;
  width: 1.5rem;
  height: 1.5rem;
  left: -1.75rem; }

.custom-checkbox.checkbox-square .custom-control-input:checked ~ .custom-control-label::after {
  border-radius: 0; }

.expertise-field-container {
  border-bottom: 2px solid #f7f7f7; }

.triangle-1 {
  height: 0;
  width: 0;
  margin-left: 10px;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f7f7f7;
  border-left: 15px solid transparent; }

.abilities-subselect {
  background-color: #f7f7f7;
  border-radius: 3px;
  padding: 2rem;
  margin-left: 0rem;
  margin-right: 0rem; }
  .abilities-subselect span {
    font-family: "HELV-NEUE-LIGHT", sans-serif; }

input[type="date"] {
  border: 2px solid #efefef;
  width: 100%; }

.date-input::-webkit-datetime-edit {
  padding: 1em; }

.date-input::-webkit-calendar-picker-indicator {
  display: none; }

.expertise-selection .tag-selected {
  min-height: 40px;
  border-radius: 20px;
  background-color: #0f4d95; }
  .expertise-selection .tag-selected p {
    font-family: "DIN-MED", sans-serif;
    font-size: 18px;
    line-height: 23px;
    color: white; }

.expertise-selection .btn-delete-white {
  cursor: pointer; }

.expertise-selection .tag-fields {
  background-color: #f7f7f7;
  border-radius: 3px;
  padding: 1rem;
  text-align: left;
  max-height: 300px;
  overflow-y: scroll;
  border-bottom: 2px solid #f7f7f7; }
  .expertise-selection .tag-fields .tag-btn {
    color: #0f4d95;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    background-color: white;
    border: 2px solid #efefef;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.4;
    font-size: 1.1rem; }
    .expertise-selection .tag-fields .tag-btn:before {
      content: "";
      display: inline-flex;
      width: 20px;
      height: 20px;
      background: transparent url(/static/media/icon_addSkill.f7c0d97e.svg) no-repeat center;
      background-size: contain;
      margin-right: 0.5rem; }

.template .title-template {
  font-family: "DIN-BOLD", sans-serif;
  font-size: 1.1rem; }

.template .text-template {
  font-family: "HELV-NEUE-REG", sans-serif;
  font-size: 1.1rem; }

.to-upload .to-upload-line {
  border-top: 1px solid #dddddd; }

.to-upload .subselect-toupload {
  height: 70px; }

.to-upload .add-file {
  cursor: pointer; }

.canvas {
  border-radius: 50%;
  max-width: 150px;
  max-height: 150px; }

/**
 * CSS for notification SweetAlert2 Toast
 */
.toast-notif {
  z-index: 10000;
  background-color: white; }
  .toast-notif.top-right {
    position: fixed;
    top: 20px;
    right: 20px; }

.offers form {
  border-radius: 3px;
  font-family: "HELV-NEUE-REG", sans-serif; }

.offers .disponibilities-text {
  font-size: 1.5rem;
  font-family: "HELV-NEUE-LIGHT", sans-serif; }

.offers .title {
  color: #0f4d95;
  font-family: "DIN-MED", sans-serif;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 2rem; }

.offers .search {
  position: relative; }
  .offers .search button {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.offers .description {
  font-size: 1.5rem; }

.offers .theme-container {
  background-color: #f7f7f7;
  padding: 15px;
  display: inline-block;
  font-family: "HELV-NEUE-REG", sans-serif; }

.offers .offers-infos h3 {
  color: #666666;
  font-family: "HELV-NEUE-REG", sans-serif;
  font-size: 1rem; }

.offers .offers-infos p {
  color: black;
  font-size: 1.1rem;
  font-family: "DIN-BOLD", sans-serif; }

.offers .underligne {
  border-bottom: 1px solid #efefef; }

.underligne {
  border-bottom: 1px solid #efefef; }

.offre .title-container {
  background-color: #fdfdfd; }
  .offre .title-container .theme-container {
    background-color: #f7f7f7;
    padding: 15px;
    display: inline-block;
    font-family: "HELV-NEUE-REG", sans-serif; }
  .offre .title-container h1 {
    font-family: "DIN-BOLD", sans-serif; }

.offre .description-block-offer h2 {
  color: #666666;
  font-family: "HELV-NEUE-REG", sans-serif;
  font-size: 1.1rem; }

.offre .description-block-offer p {
  color: black;
  font-size: 1.1rem;
  font-family: "DIN-BOLD", sans-serif; }

.offre .download-pdf img {
  margin-right: 0.5rem; }

.offre .download-pdf p {
  color: black;
  font-size: 1.4rem;
  font-family: "DIN-BOLD", sans-serif; }

.offre .description-project h2 {
  font-family: "DIN-LIGHT", sans-serif;
  font-size: 2.5rem; }

.offre .description-project h3 {
  color: #666666;
  font-family: "HELV-NEUE-REG", sans-serif;
  font-size: 1.1rem; }

.offre .description-project .see-on-web-container {
  background-color: #f7f7f7;
  padding: 15px; }

.offre .description-project .description-block-project p {
  color: black;
  font-size: 1.1rem;
  font-family: "DIN-BOLD", sans-serif; }

.offre .description-project .encoche-title {
  height: 5px;
  width: 75px;
  background: linear-gradient(45deg, #0f4d95 0%, #da413f 100%); }

.ajax-progress {
  background: rgba(255, 255, 255, 0.8) url(/static/media/compass.682189e9.svg) center no-repeat;
  background-size: 50%;
  -webkit-animation: rotating 0.5s linear infinite;
  animation: rotating 0.5s linear infinite; }
  .ajax-progress.center {
    position: fixed;
    z-index: 99999999;
    text-align: center;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 10px transparent;
    padding: 2rem; }
  .ajax-progress.alert {
    top: 10px;
    border-radius: 50%;
    border: 10px transparent;
    padding: 2rem; }

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.missions h1 {
  font-family: "DIN-LIGHT", sans-serif;
  font-size: 2.5rem; }

.missions h2 {
  font-family: "DIN-LIGHT", sans-serif;
  font-size: 2rem;
  color: #061922; }

.missions h3 {
  font-family: "DIN-MED", sans-serif;
  color: #0e4d95;
  font-size: 1.4rem; }

.missions .date .title {
  color: #666666;
  font-family: "HELV-NEUE-REG", sans-serif;
  font-size: 0.8rem; }

.missions .date .content {
  color: black;
  font-size: 1.1rem;
  font-family: "DIN-BOLD", sans-serif; }

.missions .bottom-underligne {
  border-bottom: 2px solid #f7f7f7; }

.missions .subtitle {
  font-family: "HELV-NEUE-LIGHT", sans-serif;
  font-size: 1.3rem;
  color: #333333; }

.encoche-title {
  height: 5px;
  width: 75px;
  background: linear-gradient(45deg, #0f4d95 0%, #da413f 100%); }

.tools h1 {
  font-family: "DIN-LIGHT", sans-serif;
  font-size: 2.5rem; }

.tools.active {
  background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
  color: white; }

.card.alert-card .close {
  position: absolute;
  top: 1rem;
  right: 1rem; }
  .card.alert-card .close img {
    width: 40px;
    height: 40px; }

.card.alert-card .logotype {
  height: 200px;
  margin-right: 2rem; }

/*************************/
/******* Pagination ******/
/*************************/
.pagination .page-item {
  position: relative;
  margin: 0 0.25rem; }
  .pagination .page-item.disabled {
    cursor: not-allowed; }
  .pagination .page-item.previous {
    margin-right: 1.25rem; }
  .pagination .page-item.next {
    margin-left: 1.25rem; }
  .pagination .page-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #efefef;
    border-radius: 50%; }
  .pagination .page-item .page-link {
    border: none;
    color: #333333;
    padding: 1rem;
    background: none;
    font-family: "HELV-NEUE-REG", sans-serif;
    font-size: 1.1rem;
    box-shadow: none; }
    .pagination .page-item .page-link.page-link-arrow:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 20px;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background: transparent url(/static/media/icon-chevron.fe354446.svg) no-repeat center; }
    .pagination .page-item .page-link.page-link-arrow.next:before {
      -webkit-transform: translate(-50%, -50%) rotate(180deg);
              transform: translate(-50%, -50%) rotate(180deg); }
  .pagination .page-item.active:before {
    background-image: linear-gradient(-135deg, #9e292e 30%, #15326a 100%); }
  .pagination .page-item.active .page-link {
    color: white;
    font-weight: bold; }

table thead tr th {
  width: 15%; }
  table thead tr th span {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center; }
  table thead tr th:first-of-type {
    width: 25%; }

input[type="text"] {
  outline: none; }
  input[type="text"]:not(.form-control-plaintext) {
    border: 2px solid #efefef; }

.title.notification {
  color: #0f4d95;
  font-family: "DIN-MED", sans-serif;
  font-size: 18px;
  line-height: 23px; }

.title.notification {
  color: #0f4d95;
  font-family: "DIN-MED", sans-serif;
  font-size: 18px;
  line-height: 23px; }

