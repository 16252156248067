@font-face {
    font-family: 'DIN-BOLD';
    src: url('../fonts/DINNextLTPro-Bold.otf')
}
@font-face {
    font-family: 'DIN-MED';
    src: url('../fonts/DINNextLTPro-Medium.otf')
}
@font-face {
    font-family: 'DIN-LIGHT';
    src: url('../fonts/DINNextLTPro-Light.otf');
}

@font-face {
    font-family: 'HELV-NEUE-LIGHT';
    src: url('../fonts/HelveticaNeueLTStd-Lt.otf');
}

@font-face {
    font-family: 'HELV-NEUE-REG';
    src: url('../fonts/helvetica-regular.ttf');
}

@font-face {
    font-family: 'HELV-NEUE-BLD';
    src: url('../fonts/HelveticaNeueLTStd-Cn.otf');
}
