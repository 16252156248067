@import url("../fonts/fonts.css");
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/_breakpoints";

/** Mixin **/
@mixin border-radius($pixel) {
  border-radius: $pixel;
}

/** Font **/
$din-bold: "DIN-BOLD", sans-serif;
$din-med: "DIN-MED", sans-serif;
$din-light: "DIN-LIGHT", sans-serif;
$helvetica-bold: "HELV-NEUE-BLD", sans-serif;
$helvetica-reg: "HELV-NEUE-REG", sans-serif;
$helvetica-light: "HELV-NEUE-LIGHT", sans-serif;

/** Variable **/
$grey-efe: #efefef;
$grey-border: #efefef;
$black: #333333;
$red: #da413f;
$grey-small: #aeaeae;
$blue: #0e4d95;
/*** Size ***/
$size-14: 1rem;
$size-18: 1.1rem;
$size-24: 1.25rem;
$size-40: 3rem;
$size-24: 1.4rem;
/*** Gradient ***/
$gradient-text: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);

ol, ul {
  font-size: 1.11rem;
  font-family: $helvetica-reg;
}

.text-size-low {
  font-size: 0.7rem;
}

.text-size-1 {
  font-size: $size-14;
}

.pt-6 {
  padding-top: 6rem;
}

.react-datepicker-popper {
  display: none;
}

.input-file {
  display: none;
}

.white-highlight {
  border-radius: 20px;
  padding-left: 1%;
  padding-right: 1%;
  background-color: white;

  span {
    color: $black;
  }
}

.is-invalid {
  // margin-top: -20px;
  color: $red;
  font-style: italic;
  font-size: $size-18;
}

.sticky-top {
  top: 120px;
}

p {
  font-size: $size-18;
}

a {
  color: $black;
}

small {
  font-size: 80% !important;
  font-weight: 400;

  span {
    font-size: inherit !important;
  }
}

.grey-small {
  color: $grey-small;
}

#sidebar-left {
  background: $blue url("../Images/visuel_onboardingBackground.png") no-repeat top left;
  background-size: 100%;
  h1 {
    font-size: $size-40;
    line-height: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);
  }

  p {
    font-size: $size-18;
    line-height: 27px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }
}

#sidebar-right {
  background-color: white;
  position: relative;
  z-index: 0;
  padding-top: 3rem;
  @include media-breakpoint-up(sm) {
    padding-top: 7rem;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    overflow-x: hidden;
  }
}

.min-height-100 {
  min-height: 100vh;
}

.blue {
  color: $blue;
}

.btn {
  cursor: pointer;

  &.transparent {
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
    box-shadow: none;
  }

  &.btn-signin {
    color: black;
    border-radius: 20px;
    border-width: 2px;
    border-color: $grey-border;
    padding: 0.5rem 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 400px;
    font-family: $din-med;
  }

  &.btn-footer {
    border-radius: 20px;
    background-color: white;
    color: #0e4d95;
    padding: 0.5rem 1.5rem;
    font-family: $din-med;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease 0s;
  }

  &.btn-civipol {
    border-radius: 20px;
    color: white;
    background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
    border: #da413f;
    padding: 0.5rem 1.5rem 0.25rem;
    font-family: $din-med;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease 0s;

    &:hover {
      background-image: linear-gradient(-45deg, #9e292e 0%, #15326a 100%);
    }
  }

  &.btn-calendar {
    z-index: 10;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: url("../Icons/icon_form.svg") no-repeat center;
    background-size: contain;
  }
}

form {
  label {
    font-size: $size-18;

    &[for="search"] {
      font-size: 1.5rem;
    }

    span {
      font-family: $helvetica-light;
    }

    &.is-invalid {
      font-weight: bold;
    }
  }

  .search-input {
    -moz-box-shadow: 2px 2px 2px 2px #ccc;
    -webkit-box-shadow: 2px 2px 2px 2px #ccc;
    box-shadow: 2px 2px 2px 2px #ccc;
  }

  .form-control {
    border: 2px solid $grey-border;
    height: 60px;
    @include border-radius(3px);
    font-family: $helvetica-reg;
    display: inline-flex;
    margin-bottom: 0;

    &[name="identity.birthday"] {
      background-color: white;
    }

    &::placeholder {
      font-family: $helvetica-reg;
    }

    &.is-invalid {
      border-color: $grey-border;
      background-image: none;
      padding: 0.375rem 0.75rem;
    }

    &.search {
      height: 80px;
    }
  }

  .is-invalid {
    color: $red;
    // font-style: italic;
  }

  .is-invalid-label {
    color: $red;
    font-weight: bold;
  }

  .invalid-feedback {
    margin-top: 10px;
    color: $red;
    font-style: italic;
    font-size: $size-18;
  }
}

div[name="abilities"] {
  :not(.underligne) {
    form[name="abilities"] {
      overflow: inherit !important;

      .css-1l76avi-control,
      .css-zfes0w-control,
      .css-zfes0w-control:hover {
        min-height: 60px;
        height: inherit !important;
      }
    }
  }
}

div[name="individualsInformations"] {
  :not(.underligne) {
    form[name="individualsInformations"] {
      overflow: inherit !important;

      .css-1l76avi-control,
      .css-zfes0w-control,
      .css-zfes0w-control:hover {
        min-height: 60px;
        height: inherit !important;
      }
    }
  }
}

.form-group {
  overflow: inherit !important;

  .css-1l76avi-control,
  .css-zfes0w-control,
  .css-zfes0w-control:hover {
    min-height: 60px;
    height: inherit !important;
  }
}

.invalid-feedback {
  margin-top: 10px;
  color: $red;
  font-style: italic;
  font-size: $size-18;
}

.password-advice {
  display: inline-block;
  font-family: $helvetica-light;
  font-size: 14px;
  line-height: 16px;
  text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  color: #666666;
  font-weight: 400;
  text-align: justify;
}

#title-block {
  margin: 0;
  height: 100px;

  &.account {
    background: midnightblue url("../Images/visuel_pushLogin.png") no-repeat center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &.sign-up {
    background: midnightblue url("../Images/visuel_pushInscription.png") no-repeat center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  h2 {
    font-size: $size-18;
    font-weight: 700;
  }
}

#reinit-form {
  font-family: $din-light;

  .encoche-title {
    height: 5px;
    width: 75px;
    background: linear-gradient(45deg, #0f4d95 0%, #da413f 100%);
  }
}

#login-form {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 420px;
  }

  .form {
    border-bottom: 2px solid $grey-border;
    border-right: 2px solid $grey-border;
    border-left: 2px solid $grey-border;
    @include border-radius(3px);

    &.reinit-form {
      // width: 650px;
      border-top: 2px solid $grey-border;

      .title {
        color: #0f4d95;
        font-family: $din-med;
        font-size: 18px;
        line-height: 23px;
      }
    }

    .title-block {
      margin: 0;
      height: 100px;

      &.employee {
        background: midnightblue url("../Images/background_header.png") no-repeat center;
        background-size: cover;
        height: 120px;
      }

      &.account {
        background: midnightblue url("../Images/visuel_pushLogin.png") no-repeat center;
        background-size: cover;
      }

      &.subscribe {
        background: midnightblue url("../Images/visuel_pushInscription.png") no-repeat center;
        background-size: cover;
      }

      h2 {
        font-size: $size-18;
      }
    }
  }
}

.employee-auth {
  background: midnightblue url("../Images/visuel_onboardingBackground.png") no-repeat center;
  background-size: cover;
}

.title-block {
  margin: 0;
  height: 100px;

  &.employee {
    background: midnightblue url("../Images/background_footer_smartphone.png") no-repeat center;
    background-size: cover;
  }

  &.account {
    background: midnightblue url("../Images/visuel_pushLogin.png") no-repeat center;
    background-size: cover;
  }

  &.subscribe {
    background: midnightblue url("../Images/visuel_pushInscription.png") no-repeat center;
    background-size: cover;
  }

  h2 {
    font-size: $size-18;
  }
}

#create-account {
  background: midnightblue url("../Images/visuel_pushInscription.png") no-repeat center;
  background-size: cover;
  @include border-radius(3px);
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 420px;
  }

  h2 {
    font-size: $size-18;
    font-family: $din-bold;
  }
}

.circle-mail-confirmation {
  background-color: #fbfbfb;
  border-radius: 50%;
  text-align: center;
  width: 500px;
  height: 500px;
  position: relative;
}

.logo-mail {
  top: 0;
  margin-top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.content-text-circle-mail {
  padding: 10px;
  margin-top: 4rem;
  left: 0;
}

#navbar {
  background: white url("../Images/background_header.png") no-repeat left center;
  background-size: cover;
  min-height: 270px;

  h1 {
    font-family: $din-bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);
    line-height: 58px;
    font-size: 3rem;
  }

  .top-header {
    background-color: rgba(0, 0, 0, 0.25);

    .language {
      cursor: pointer;
      &:before {
        content: "";
        display: inline-flex;
        width: 15px;
        height: 15px;
        margin-right: 0.25rem;
        background: #0000 url(../Icons/icon_language.svg) no-repeat center;
        background-size: contain;
      }
    }

    .language:hover {
      text-decoration: underline;
    }

    .top-header-menu {
      @include media-breakpoint-up(sm) {
        height: 40px;
      }

      p {
        color: white;
        font-size: 0.9rem;
      }

      a {
        color: white;
      }

      .is-validating-notif {
        background-color: white;
        color: $black;
        border-radius: 20px;
        font-size: 0.9rem;
      }
    }
  }
}

.profile-picture {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.profile-picture-large {
    width: 100px;
    height: 100px;
  }
}

#footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .top-footer {
    padding: 1rem 0;
    background: url("../Images/background_footer_desktop.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-up(lg) {
      height: 100px;
    }
    img {
      height: 65px;
    }
    aside {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 15px;
        right: 0;
      }
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
    .nav-item {
      padding: 0 0.5rem;
      p {
        margin-bottom: 0;
      }
    }
  }

  .sub-footer {
    padding-top: 2rem;
    padding-right: 0px;
    padding-bottom: 0.5rem;
    padding-left: 0px;

    background-color: #efefef;
    font-family: $helvetica-light;
    font-size: 1.2rem;
    p {
      color: #333;
      font-family: "Helvetica Neue", Arial, Sans-Serif;
      font-size: 14px;
      a {
        color: #333;
      }
    }

    #menu-link-footer {
      flex-direction: row;

      li {
        a {
          color: #333;
          font-family: "Helvetica Neue", Arial, Sans-Serif;
          margin: 0 0 0 0.75rem;
          padding: 0;
          font-size: 14px !important;
          &:hover {
            color: #333;
          }
        }
      }
    }
  }
}

.profil_wrapper {
  padding-top: 4rem;
  padding-left: 7.4rem;
  padding-right: 7.4rem;
}

.notifications {
  border: 2px solid $grey-border;
  border-radius: 3px;
  padding: 2rem;

  .title {
    font-family: $din-med;
    font-size: 1.2rem;
    line-height: 3rem;
    color: #061922;
    text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  }

  .text {
    font-family: $helvetica-light;
    font-size: 1.1rem;
  }

  .btn-close-notif {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: transparent url("../../Static/Icons/icon_close-gris.svg") no-repeat center;
    background-size: contain;
  }
}

#base-experts {
  background-color: white;
  border-radius: 20px;
  width: 180px;
  height: 22px;

  p {
    background: $gradient-text;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    line-height: 1.4;
    text-shadow: none;
  }
}

#rgpdButton {
  background-color: white;
  border: none;
  color: black;
  text-decoration: none;
  span {
    &:hover{
      text-decoration: underline;
      color: blue;
    }
  }
}

#menu {
  -ms-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;

  .btn-menu {
    cursor: pointer;
    border: 2px solid $grey-border;
    border-radius: 3px;
    padding: 0.5rem;
    line-height: 1;
    font-size: 1.2rem;
    margin-top: 0.7rem;
    align-items: center;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }

    &.mission {
      height: 130px;
      font-family: $din-med;
    }

    &.active {
      background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
      color: white;
    }

    .number-menu {
      margin-right: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      width: 3rem;
      height: 3rem;
      padding-top: 1rem;
      padding-bottom: 0.75rem;
      border-radius: 50%;
      background-color: #f7f7f7;
      font-family: $din-med;

      &.active-number {
        color: black;
        background-color: white;
      }
    }
  }
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.profile-container {
  p {
    &.lang-subtitle {
      font-family: $din-med;
      font-size: $size-18;
    }

    font-family: $helvetica-light;
  }

  h1 {
    font-family: $din-light;
    text-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
    font-size: 30px;
    @include media-breakpoint-up(sm) {
      line-height: 43px;
      font-size: 36px;
    }
    color: #000000;
    margin-bottom: 1.5rem;
    margin-left: 20px;
    padding-top: 5px;
  }

  h3 {
    font-family: $din-med;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 2rem;
  }

  .number-menu {
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    background-color: #f7f7f7;
  }

  .profile-sections {
    border: 2px solid $grey-border;
    border-radius: 3px;
    padding: 1rem;
    @include media-breakpoint-up(sm) {
      padding: 2rem;
    }
    // font-family: $din-light;
    width: 100%;

    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        display: block;
      }
    }

    .form-control {
      &::placeholder {
        color: #495057;
        font-style: normal;
      }

      &:-ms-input-placeholder {
        color: #495057;
        font-style: normal;
      }

      &::-ms-input-placeholder {
        color: #495057;
        font-style: normal;
      }
    }

    .title {
      color: #0f4d95;
      font-family: $din-med;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 2rem;
    }
  }

  .confirmation-container {
    margin-top: -4px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #f7f7f7;
    border-radius: 0 0 3px 3px;
    width: 100%;
    text-align: center;
    border-left: 2px solid $grey-border;
    border-bottom: 2px solid $grey-border;
    border-right: 2px solid $grey-border;
  }

  .register-modification {
    text-align: center;
    width: 100%;
    // border-left: 2px solid $grey-border;
    // border-bottom: 2px solid $grey-border;
    // border-right: 2px solid $grey-border;
  }
}

//custom radio x checkbox

.custom-radio {
  padding-left: 2rem;
}

.form-check-inline .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  width: 2rem;
  height: 2rem;
}

.custom-control-label::selection {
  background-color: transparent;
  p,
  span {
    background-color: transparent;
  }
}

.custom-control-input ~ .custom-control-label::before {
  left: -2rem;
  top: 0;
  border-color: $grey-border;
  background-color: white;
  width: 2rem;
  height: 2rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  left: -2rem;
  top: 0.25rem;
  border-color: $grey-border;
  background-color: white;
  width: 2rem;
  height: 2rem;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: black;
  border-radius: 50%;
  border: 0.25rem solid white;
  width: 1.5rem;
  height: 1.5rem;
  left: -1.75rem;
}

.custom-checkbox {
  padding-left: 2rem;
}

.custom-control-input ~ .custom-control-label::before {
  left: -2rem;
  top: 0;
  border-color: $grey-border;
  background-color: white;
  width: 2rem;
  height: 2rem;
}

.custom-control-input ~ .custom-control-label::after {
  width: 2rem;
  height: 2rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  left: -2rem;
  top: 0;
  border-color: $grey-border;
  background-color: white;
  width: 2rem;
  height: 2rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: white url("../Icons/icon_checkbox.svg") no-repeat center;
  width: 1.5rem;
  height: 1.5rem;
  left: -1.75rem;
}

.custom-checkbox.checkbox-square .custom-control-input:checked ~ .custom-control-label::after {
  border-radius: 0;
}

.expertise-field-container {
  border-bottom: 2px solid #f7f7f7;
}

.triangle-1 {
  height: 0;
  width: 0;
  margin-left: 10px;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f7f7f7;
  border-left: 15px solid transparent;
}

.abilities-subselect {
  background-color: #f7f7f7;
  border-radius: 3px;
  padding: 2rem;
  margin-left: 0rem;
  margin-right: 0rem;

  span {
    font-family: $helvetica-light;
  }
}

input[type="date"] {
  border: 2px solid #efefef;
  width: 100%;
}

.date-input::-webkit-datetime-edit {
  padding: 1em;
}

.date-input::-webkit-calendar-picker-indicator {
  display: none;
}

.expertise-selection {
  .tag-selected {
    min-height: 40px;
    border-radius: 20px;
    background-color: #0f4d95;

    p {
      font-family: $din-med;
      font-size: 18px;
      line-height: 23px;
      color: white;
    }
  }

  .btn-delete-white {
    cursor: pointer;
  }

  .tag-fields {
    background-color: #f7f7f7;
    border-radius: 3px;
    padding: 1rem;
    text-align: left;
    max-height: 300px;
    overflow-y: scroll;
    border-bottom: 2px solid #f7f7f7;

    .tag-btn {
      color: #0f4d95;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      background-color: white;
      border: 2px solid $grey-border;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1.4;
      font-size: $size-18;

      &:before {
        content: "";
        display: inline-flex;
        width: 20px;
        height: 20px;
        background: transparent url("../../Static/Icons/icon_addSkill.svg") no-repeat center;
        background-size: contain;
        margin-right: 0.5rem;
      }
    }
  }
}

.template {
  .title-template {
    font-family: $din-bold;
    font-size: $size-18;
  }

  .text-template {
    font-family: $helvetica-reg;
    font-size: $size-18;
  }
}

.to-upload {
  .to-upload-line {
    border-top: 1px solid #dddddd;
  }

  .subselect-toupload {
    // background-color: #f7f7f7;
    // border-radius: 3px;
    height: 70px;
  }

  .add-file {
    cursor: pointer;
  }
}

.canvas {
  border-radius: 50%;
  max-width: 150px;
  max-height: 150px;
}

/**
 * CSS for notification SweetAlert2 Toast
 */
.toast-notif {
  z-index: 10000;
  background-color: white;

  &.top-right {
    position: fixed;
    top: 20px;
    right: 20px;
  }
}

.offers {
  form {
    @include border-radius(3px);
    font-family: $helvetica-reg;
  }

  .disponibilities-text {
    font-size: 1.5rem;
    font-family: $helvetica-light;
  }

  .title {
    color: #0f4d95;
    font-family: $din-med;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 2rem;
  }

  .search {
    position: relative;

    button {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .description {
    font-size: 1.5rem;
  }

  .theme-container {
    background-color: #f7f7f7;
    padding: 15px;
    display: inline-block;
    font-family: $helvetica-reg;
  }

  .offers-infos {
    h3 {
      color: #666666;
      font-family: $helvetica-reg;
      font-size: $size-14;
    }

    p {
      color: black;
      font-size: $size-18;
      font-family: $din-bold;
    }
  }

  .underligne {
    border-bottom: 1px solid $grey-border;
  }
}

.underligne {
  border-bottom: 1px solid $grey-border;
}

.offre {
  .title-container {
    background-color: #fdfdfd;

    .theme-container {
      background-color: #f7f7f7;
      padding: 15px;
      display: inline-block;
      font-family: $helvetica-reg;
    }

    h1 {
      font-family: $din-bold;
    }
  }

  .description-block-offer {
    h2 {
      color: #666666;
      font-family: $helvetica-reg;
      font-size: $size-18;
    }

    p {
      color: black;
      font-size: $size-18;
      font-family: $din-bold;
    }
  }

  .download-pdf {
    img {
      margin-right: 0.5rem;
    }

    p {
      color: black;
      font-size: $size-24;
      font-family: $din-bold;
    }
  }

  .description-project {
    h2 {
      font-family: $din-light;
      font-size: 2.5rem;
    }

    h3 {
      color: #666666;
      font-family: $helvetica-reg;
      font-size: $size-18;
    }

    .see-on-web-container {
      background-color: #f7f7f7;
      padding: 15px;
      // display: inline-block;
    }

    .description-block-project {
      p {
        color: black;
        font-size: $size-18;
        font-family: $din-bold;
      }
    }

    .encoche-title {
      height: 5px;
      width: 75px;
      background: linear-gradient(45deg, #0f4d95 0%, #da413f 100%);
    }
  }
}

//LOADER
.ajax-progress {
  &.center {
    position: fixed;
    z-index: 99999999;
    text-align: center;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 10px transparent;
    padding: 2rem;
  }

  &.alert {
    top: 10px;
    border-radius: 50%;
    border: 10px transparent;
    padding: 2rem;
  }

  background: rgba(255, 255, 255, 0.8) url("../logos/compass.svg") center no-repeat;
  background-size: 50%;
  -webkit-animation: rotating 0.5s linear infinite;
  -moz-animation: rotating 0.5s linear infinite;
  -ms-animation: rotating 0.5s linear infinite;
  -o-animation: rotating 0.5s linear infinite;
  animation: rotating 0.5s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.missions {
  h1 {
    font-family: $din-light;
    font-size: 2.5rem;
  }

  h2 {
    font-family: $din-light;
    font-size: 2rem;
    color: #061922;
  }

  h3 {
    font-family: $din-med;
    color: $blue;
    font-size: 1.4rem;
  }

  .date {
    .title {
      color: #666666;
      font-family: $helvetica-reg;
      font-size: 0.8rem;
    }
    .content {
      color: black;
      font-size: $size-18;
      font-family: $din-bold;
    }
  }

  .bottom-underligne {
    border-bottom: 2px solid #f7f7f7;
  }

  .subtitle {
    font-family: $helvetica-light;
    font-size: 1.3rem;
    color: #333333;
  }
}

.encoche-title {
  height: 5px;
  width: 75px;
  background: linear-gradient(45deg, #0f4d95 0%, #da413f 100%);
}

.tools {
  h1 {
    font-family: $din-light;
    font-size: 2.5rem;
  }

  &.active {
    background-image: linear-gradient(-45deg, #da413f 0%, #0f4d95 100%);
    color: white;
  }
}

.card {
  &.alert-card {
    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .logotype {
      height: 200px;
      margin-right: 2rem;
    }
  }
}

/*************************/
/******* Pagination ******/
/*************************/

.pagination {
  .page-item {
    position: relative;
    margin: 0 0.25rem;

    &.disabled {
      cursor: not-allowed;
    }

    &.previous {
      margin-right: 1.25rem;
    }

    &.next {
      margin-left: 1.25rem;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
      background-color: $grey-efe;
      border-radius: 50%;
    }

    .page-link {
      border: none;
      color: $black;
      padding: 1rem;
      background: none;
      font-family: $helvetica-reg;
      font-size: $size-18;
      box-shadow: none;

      &.page-link-arrow {
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 20px;
          transform: translate(-50%, -50%);
          background: transparent url("../Icons/icon-chevron.svg") no-repeat center;
        }

        &.next {
          &:before {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }
    }

    &.active {
      &:before {
        background-image: linear-gradient(-135deg, #9e292e 30%, #15326a 100%);
      }

      .page-link {
        color: white;
        font-weight: bold;
      }
    }
  }
}

//Tableau d'evaluations
table {
  thead {
    tr {
      th {
        width: 15%;
        span {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:first-of-type {
          width: 25%;
        }
      }
    }
  }
}

input[type="text"] {
  outline: none;
  &:not(.form-control-plaintext) {
    border: 2px solid #efefef;
  }
}

.title {
  &.notification {
    color: #0f4d95;
    font-family: $din-med;
    font-size: 18px;
    line-height: 23px;
  }
}

.title {
  &.notification {
    color: #0f4d95;
    font-family: $din-med;
    font-size: 18px;
    line-height: 23px;
  }
}

