$block_title_1: url("../Images/visuel_pushInscription.png");
$block_title_2: url("../Images/visuel_pushLogin.png");



.login-form {
        width: 400px;

}

.block-title {
        background-image: $block_title_1;
}

.block-signup-title{
        background-image: $block_title_2;

}

.white-color{
        color: #fff;        
}

.inputBase{
        height: 50px;
        border: 2px solid #EFEFEF;
        border-radius: 3px 3px 0 0;
}

.borderStyle{
        border: 2px solid #EFEFEF;
        border-radius: 3px 3px 0 0;
}


.errorInputs{
        font-style: italic;
        color: #DA413F;
        text-align: left;
}

.grecaptcha-badge { 
        bottom:50px !important; 
}